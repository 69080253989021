<script>
export default {
	lang: 'shop',
	inject: ['getFilterRoute', 'getBreadcrumbRoute'],
	props: {
		loading: Boolean,
		title: String,
		totalResults: Number,
		categoryItems: Array,
		collectionItems: Array,
		brandItems: Array,
		sortByItems: Array,
		attrsGroups: Array,
		removalChips: Array,
		breadcrumbs: Array,
	},
}
</script>

<template>
	<div class="white--text">
		<div v-if="loading">
			<div v-for="i of 2" :key="i" class="mb-10">
				<v-skeleton-loader type="heading" loading />
				<br />
				<v-skeleton-loader type="text" v-for="j of 3" :key="j" loading class="my-3 pr-10" />
			</div>
		</div>
		<div v-else>
			<v-breadcrumbs :items="breadcrumbs" v-if="breadcrumbs.length">
				<template v-slot:divider>
					<v-icon>mdi-chevron-right</v-icon>
				</template>
				<template v-slot:item="{ item }">
					<router-link
						class="breadcrumb-item font-weight-bold font-subtitle-1 px-0 text-uppercase"
						:to="getBreadcrumbRoute(item.value)"
					>
						{{ item.text }}
					</router-link>
				</template>
			</v-breadcrumbs>
			<div class="font-4 font-weight-bold text-uppercase">
				{{ title }}
			</div>
			<div class="mt-1">{{ totalResults }} {{ 'resultado/s' | lang }}</div>
			<v-chip
				v-for="item of removalChips"
				:key="item.text"
				class="mt-4 mr-4"
				close
				@click="$router.push(getFilterRoute(item.type, item.value, false))"
				@click:close="$router.push(getFilterRoute(item.type, item.value, false))"
			>
				{{ item.text }}
			</v-chip>
			<ShopFiltersGroup :items="collectionItems" type="collection" />
			<ShopFiltersGroup :items="sortByItems" :title="$lang('Ordenar por')" type="sortBy" />
			<ShopFiltersGroup :items="categoryItems" :title="$lang('Categoría')" type="category" :limit="20" />
			<ShopFiltersGroup :items="brandItems" :title="$lang('Marca')" type="brand" />
			<ShopFiltersGroup
				v-for="(group, i) of attrsGroups"
				:key="i"
				:items="group.items"
				:title="group.title"
				type="attr"
			/>
		</div>
	</div>
</template>

<style scoped>
.v-breadcrumbs {
	padding: 0 0 16px 0;
	display: block;
}
.v-breadcrumbs >>> a,
.v-breadcrumbs >>> .v-breadcrumbs__divider {
	display: inline;
	vertical-align: middle;
	padding: 0;
	transition: opacity 0.3s;
}
.v-breadcrumbs >>> .v-breadcrumbs__divider {
	position: relative;
	top: -1px;
}
.v-breadcrumbs >>> a:not(:hover) {
	opacity: 0.6;
}
</style>
